import React, { FC, useContext, useEffect, useState } from 'react'
import { infoBlue, warningYellowIcon } from '@images/icons'
import { Icon } from '@components/ui/Icon'
import * as pageUtils from '@components/homepage/__index.utils'
import Text from '@components/ui/text'
import axios from 'axios'
import { AppStateContext } from '@components/layouts/DynamicLayout'
import { transformInformationStrapiData } from '@utils/methods'
import { informationMessageDic } from 'typings/shared'
import { joinClasses } from '@utils/styles'

const HomepageInfo: FC<pageUtils.HomepageInfoProps> = () => {
	const [rawData, setRawData] = useState<informationMessageDic>(
		{} as informationMessageDic
	)
	const [hideInformation, setHideInformation] = useState<boolean>(true)
	const { language } = useContext(AppStateContext)

	const bannerIcon = {
		Info: infoBlue,
		Alert: warningYellowIcon
	}

	useEffect(() => {
		axios
			.get(
				`${process.env.STRAPI_API}/api/${process.env.STRAPI_API_INFORMATION}?locale=${language}&populate=*`
			)
			.then(async (res) => {
				const data = transformInformationStrapiData(res.data)
				setHideInformation(data.hide)
				setRawData(data)
			})
			.catch((e) => {
				console.error(e)
			})
	}, [language])

	useEffect(() => {
		const linksContainer = document.getElementsByClassName('link-redirect')
		Array.prototype.forEach.call(linksContainer, (container: HTMLElement) => {
			const links = container.getElementsByTagName('a')
			Array.prototype.forEach.call(links, (link) => {
				link.setAttribute('target', '_blank')
			})
		})
	}, [language, rawData])

	return (
		<>
			{hideInformation && rawData[language!]?.message.value && (
				<div
					className={joinClasses([
						pageUtils.homepageInformationClasses.root,
						pageUtils.homepageInformationClasses[rawData.type],
						'link-redirect'
					])}
				>
					<Icon
						src={bannerIcon[rawData.type]}
						className={pageUtils.homepageInformationClasses.rootImg}
					/>
					<Text content={rawData[language!].message.value} />
				</div>
			)}
		</>
	)
}

export default HomepageInfo
