import React, { FC, useContext, useEffect, useRef, useState } from 'react'
import Modal from '@components/ui/modal'
import {
	chevronWhiteIcon,
	tutorialIcon1,
	tutorialIcon2,
	tutorialIcon3
} from '@images/icons'
import * as pageUtils from '@components/ui/tutorial/__index.utils'
import { formatStrapiText } from '@utils/methods'
import { AppStateContext } from '@components/layouts/DynamicLayout'
import { joinClasses } from '@utils/styles'
import TinySlider from 'tiny-slider-react'
import 'tiny-slider/dist/tiny-slider.css'
import { Icon } from '@components/ui/Icon'
import Text from '@components/ui/text'

type TutorialProp = {
	isOpen: boolean
	onclose: () => void
	isAuth?: boolean
}

const Tutorial: FC<TutorialProp> = ({ isOpen, isAuth, onclose }) => {
	const { pageData } = useContext(AppStateContext)
	const [slidecount, setSlideCount] = useState<number>()
	const [slidePositon, setSlidePosition] = useState<number>()
	const tinyRef = useRef<any>(null)

	useEffect(() => {
		const info = tinyRef?.current?.slider.getInfo()
		setSlideCount(info?.slideCount)
		setSlidePosition(info?.displayIndex)

		closeTuto()
	}, [])

	const closeTuto = () => {
		const tuto = localStorage.getItem('launchTutorial')

		!tuto && onclose()

		localStorage.removeItem('launchTutorial')
	}

	const handleNavigation = (direction?, e?) => {
		const info = tinyRef?.current?.slider.getInfo()

		if (e) {
			setSlidePosition(e?.displayIndex)
		}

		if (direction) {
			tinyRef.current?.slider.goTo(direction)
			if (direction == 'next') {
				setSlidePosition(info?.displayIndex + 1)
			} else if (direction == 'prev') {
				setSlidePosition(info?.displayIndex - 1)
			}
		}
	}

	const settings = {
		lazyload: true,
		nav: false,
		mouseDrag: true,
		controls: false,
		loop: false,
		arrowKeys: false,
		ondrag: () => alert('draged')
	}

	const items = [
		{
			title: isAuth
				? pageData?.assets?.tutorialModal_welcome_to_mon_dossier_platform
				: pageData?.assets?.tutorialModal_welcome_to_mon_dossier_platform,
			text: pageData?.assets
				?.tutorialModal_welcome_to_mon_dossier_platform_text,
			image: tutorialIcon1
		},
		{
			title: pageData?.assets?.tutorialModal_send_your_requests_online,
			text: pageData?.assets?.TutorialModal_send_your_requests_online_text,
			image: tutorialIcon2
		},
		{
			title:
				pageData?.assets
					?.tutorialModal_stay_informed_about_the_progress_of_your_requests,
			text: pageData?.assets
				?.tutorialModal_stay_informed_about_the_progress_of_your_requests_text,
			image: tutorialIcon3
		}
	]

	return (
		<>
			<Modal
				extraStyle={pageUtils.classes.tutorialModal}
				isOpen={isOpen}
				onCancelBtn={onclose}
				onSetIsOpen={() => {}}
			>
				<>
					<TinySlider
						ref={tinyRef}
						settings={settings}
						onIndexChanged={(e) => handleNavigation('', e)}
						className={joinClasses([pageUtils.classes.body, 'slider'])}
					>
						{items.map((item, index) => (
							<div key={`s-${index}`}>
								<div className={pageUtils.classes.imageContainer}>
									<img className={pageUtils.classes.image} src={item.image} />
								</div>
								<div className={pageUtils.classes.textContainer}>
									<div className={pageUtils.classes.title}>
										{formatStrapiText(item.title)}
									</div>
									<div className={pageUtils.classes.text}>
										<Text content={item.text} />
									</div>
								</div>
							</div>
						))}
					</TinySlider>
					<div className={joinClasses([pageUtils.classes.controls, 'control'])}>
						<button
							style={{ visibility: slidePositon == 1 ? 'hidden' : 'visible' }}
							className={joinClasses([pageUtils.classes.arrow, 'prev'])}
							onClick={() => handleNavigation('prev')}
						>
							<Icon src={chevronWhiteIcon} />
						</button>
						<div className={pageUtils.classes.navDotsContainer}>
							<div
								className={joinClasses([
									pageUtils.classes.navdots,
									slidePositon == 1 ? pageUtils.classes.activeDot : ''
								])}
							></div>
							<div
								className={joinClasses([
									pageUtils.classes.navdots,
									slidePositon == 2 ? pageUtils.classes.activeDot : ''
								])}
							></div>
							<div
								className={joinClasses([
									pageUtils.classes.navdots,
									slidePositon == slidecount ? pageUtils.classes.activeDot : ''
								])}
							></div>
						</div>
						<button
							className={joinClasses([
								pageUtils.classes.arrow,
								'next',
								slidePositon == slidecount ? pageUtils.classes.btnText : ''
							])}
							onClick={() =>
								slidePositon == slidecount
									? onclose()
									: handleNavigation('next')
							}
						>
							{slidePositon == slidecount ? (
								<span>{pageData.assets?.button_complete}</span>
							) : (
								<Icon src={chevronWhiteIcon} />
							)}
						</button>
					</div>
				</>
			</Modal>
		</>
	)
}

export default Tutorial
