import React, { FC, useContext, useEffect, useState } from 'react'
import Modal from '@components/ui/modal'
import * as pageUtils from '@components/ui/tutorial/__index.utils'
import { AppStateContext } from '@components/layouts/DynamicLayout'
import Text from '@components/ui/text'
import { navigate } from '@components/ui/link'
import config from '@utils/config'

const UpdateProfileModal: FC = () => {
	const { pageData, authUser } = useContext(AppStateContext)
	const [isOpen, setIsOpen] = useState<boolean>(false)

	useEffect(() => {
		closeProfileUpdate()
	}, [])

	const isArray = (value: string): boolean => {
		try {
			const parsedValue = JSON.parse(value)
			return Array.isArray(parsedValue)
		} catch (error) {
			return false
		}
	}

	const closeProfileUpdate = () => {
		const update = localStorage.getItem(config.localStorage.lastLoginDateKey)
		// if current user exists
		if (!update || !isArray(update)) return
		const userInfo = JSON.parse(update)
		const userExist = userInfo?.find(
			(user) => user.email == authUser?.profile?.email
		)
		const updateProfilePopup = localStorage.getItem('updateProfilePopup')

		if (userExist?.date && updateProfilePopup) {
			setIsOpen(true)
			localStorage.removeItem('updateProfilePopup')
		}
	}

	return (
		<>
			<Modal
				isOpen={isOpen}
				title={pageData.assets?.label_update_your_profile}
				onCancelBtn={() => {
					setIsOpen(false)
				}}
				onPrimaryButton={() => {
					navigate(config.auth.profile.baseUrl)
				}}
				onSetIsOpen={() => {}}
				showExitBtn
				primaryButtonText={pageData.assets?.label_update}
				cancelButtonText={pageData.assets?.close_label}
			>
				<div className={pageUtils.classes.textContainer}>
					<div>
						<Text
							content={pageData.assets?.label_update_your_profile_description}
						/>
					</div>
				</div>
			</Modal>
		</>
	)
}

export default UpdateProfileModal
