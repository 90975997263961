import Breakpoints from '@utils/breakpoints'
import { Colors } from '@utils/css-variables'
import { makeClasses } from '@utils/styles'

type Classes = {
	body: string
	imageContainer: string
	image: string
	textContainer: string
	title: string
	text: string
	controls: string
	arrow: string
	navDotsContainer: string
	navdots: string
	activeDot: string
	endButton: string
	btnText: string
	tutorialModal: string
	lastImage: string
}

export const classes: Classes = makeClasses({
	body: {
		padding: '10px',
		textAlign: 'center',
		[Breakpoints.maxWidth('sm')]: {
			padding: 0
		}
	},
	image: {
		maxHeight: '350px',
		[Breakpoints.maxWidth('sm')]: {
			maxWidth: '100%'
		}
	},
	title: {
		fontWeight: '700',
		fontSize: '22px',
		marginTop: '3rem',
		marginBottom: '1rem'
	},
	text: {
		color: Colors.darkGrey2
	},
	controls: {
		display: 'flex',
		justifyContent: 'space-between',
		padding: '10px 10px 20px'
	},
	arrow: {
		border: '0px solid',
		borderRadius: '50px',
		padding: '10px',
		cursor: 'pointer',
		background: Colors.secondary,
		':first-child': {
			transform: 'rotate(180deg)'
		},
		':hover': {
			background: Colors.darkBlue2
		},
		'& img': {
			width: '20px',
			verticalAlign: 'middle'
		}
	},
	navDotsContainer: {
		display: 'flex',
		alignItems: 'center',
		gap: '10px'
	},
	navdots: {
		width: '8px',
		height: '8px',
		background: Colors.greyIron,
		borderRadius: '50%'
	},
	activeDot: {
		background: Colors.secondary
	},
	btnText: {
		color: Colors.white,
		padding: '10px 30px'
	},
	endButton: {
		positioin: 'relative',
		'& a': {
			color: Colors.white + '!important',
			width: 'fit-content',
			margin: '0px'
		},
		'& > div': {
			'& div': {
				width: 'fit-content'
			}
		}
	},
	tutorialModal: {
		boxShadow: '0 3px 9px rgb(0 0 0 / 50%)',
		[Breakpoints.widthBetween('sm', 'lg')]: {
			width: '498px !important'
		},
		[Breakpoints.minWidth('md')]: {
			boxShadow: '0 5px 15px rgba(0,0,0,.5)'
		},
		[Breakpoints.maxWidth('sm')]: {
			width: '95vw !important'
		}
	}
})
