import React, { FC, useEffect, useState } from 'react'
import {
	AuthenticatedTemplate,
	UnauthenticatedTemplate
} from '@azure/msal-react'
import HomepageBanner from '@components/homepage/homepage.banner'
import HomepageQuickAccess from '@components/homepage/homepage.quickAccess'
import HomepageSidebarContent from '@components/homepage/homepage.sidebarContent'
import { PageProps } from 'gatsby'
import { QuickAccess } from 'typings/shared'
import { joinClasses, makeClasses } from '@utils/styles'
import Breakpoints from '@utils/breakpoints'
import HomepageSidebarActivities from '@components/homepage/homepage.sidebarActivities'
import Tutorial from '@components/ui/tutorial'
import HomepageInfo from '@components/homepage/homepage.info'
import InformationBanner from '@components/ui/informationBanner'
import UpdateProfileModal from '@components/ui/updateProfileModal'

const classes: { section: string } = makeClasses({
	section: {
		display: 'flex',
		flexWrap: 'wrap',
		margin: '40px 25px',
		[Breakpoints.maxWidth('xl')]: {
			margin: '40px 15px',
			'&.connected': {
				flexDirection: 'column-reverse'
			}
		},
		[Breakpoints.maxWidth('md')]: {
			margin: '20px 5px'
		}
	}
})

const HomepageTemplate: FC<
	PageProps<null, { quickAccesses: QuickAccess[] }, null, null>
> = ({ pageContext: { quickAccesses } }) => {
	const [isOpen, setIsOpen] = useState<boolean>(true)
	return (
		<>
			<AuthenticatedTemplate>
				<InformationBanner />
				<HomepageInfo />
				<HomepageBanner isUserConnected />
				<section className={joinClasses([classes.section, 'connected'])}>
					<Tutorial
						isOpen={isOpen}
						isAuth
						onclose={() => {
							setIsOpen(false)
						}}
					/>
					<UpdateProfileModal />
					<HomepageQuickAccess quickAccesses={quickAccesses} />
					<HomepageSidebarActivities />
				</section>
			</AuthenticatedTemplate>
			<UnauthenticatedTemplate>
				<InformationBanner />
				<HomepageInfo />
				<HomepageBanner />
				<section className={classes.section}>
					<Tutorial
						isOpen={isOpen}
						onclose={() => {
							setIsOpen(false)
						}}
					/>
					<HomepageQuickAccess quickAccesses={quickAccesses} />
					<HomepageSidebarContent />
				</section>
			</UnauthenticatedTemplate>
		</>
	)
}

export default HomepageTemplate
