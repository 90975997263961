import React, { FC, useContext, useEffect, useState } from 'react'
import config from '@utils/config'
import CreateAccountButton from '@components/auth/CreateAccountButton'
import SectionTitle from '@components/ui/sectionTitle'
import { PageTitle } from '@components/configs/PageTitle'
import {
	AppContextProps,
	AppStateContext
} from '@components/layouts/DynamicLayout'
import { LoginButton } from '@components/auth/LoginButton'
import { formatStrapiText } from '@utils/methods'
import { joinClasses } from '@utils/styles'
import * as pageUtils from '@components/homepage/__index.utils'
import { navigate } from '@components/ui/link'
import { Collapse as ReactCollapse } from 'react-collapse'
import { Request } from 'typings/shared'
import SearchRequests from '@components/ui/requestSearch/search'
import { useAppDispatch, useAppSelector } from '@services/store'
import {
	getIsSearchHeaderOpen,
	getSearchText
} from '@services/store/articles/selector'
import { doOpenSearchHeader, fireSearch } from '@services/store/articles'
import Loader from '@components/ui/loader'
import Text from '@components/ui/text'
import RequestListCard from '@components/ui/requestSearch/RequestListCard'

const HomepageBanner: FC<pageUtils.HomepageBannerProps> = ({
	isUserConnected
}) => {
	const { pageData, authUser } = useContext<AppContextProps>(AppStateContext)
	const [searchText, setSearchText] = useState<string>('')
	const isSearchBoxOpen: boolean = useAppSelector(getIsSearchHeaderOpen)

	const dispatch = useAppDispatch()

	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [requests, setRequests] = useState<Request[]>([])

	const onNavigateToRequests = () => {
		if (searchText.length > 2) {
			dispatch(doOpenSearchHeader(false))

			navigate('/results-requests', { state: { searchText } })
		}
	}

	useEffect(() => {
		const searchInput = document.getElementById(
			'homePageSearchInput'
		) as HTMLInputElement
		if (searchInput) {
			searchInput.value = ''
		}

		setRequests([])
	}, [])

	const isHomePageSearchInputNotEmpty = () => {
		const searchInput = document.getElementById(
			'homePageSearchInput'
		) as HTMLInputElement
		if (searchInput) {
			return searchInput.value !== ''
		}
		return false
	}

	const title = () => {
		return (
			<>
				{isUserConnected && (
					<>
						{formatStrapiText(pageData?.assets?.homepage_salutation)}{' '}
						<span className={pageUtils.homepageBannerClasses.titleColor}>
							{authUser?.profile?.firstName}
						</span>
						<br />
					</>
				)}
				{formatStrapiText(pageData?.assets?.homepage_welcome)}{' '}
				<span
					className={joinClasses([
						pageUtils.homepageBannerClasses.titleColor,
						!isUserConnected
							? pageUtils.homepageBannerClasses.breakTextMobile
							: ''
					])}
				>
					{config.homepage.monDossier}
				</span>
				{!isUserConnected && (
					<>
						<div className={pageUtils.homepageBannerClasses.buttonWrapper}>
							<LoginButton
								text={
									<>
										<span
											className={
												pageUtils.homepageBannerClasses.btnTextNoMobile
											}
										>
											{formatStrapiText(pageData.assets?.homepage_login)}
										</span>
										<span
											className={pageUtils.homepageBannerClasses.btnTextMobile}
										>
											{formatStrapiText(pageData.assets?.btn_login)}
										</span>
									</>
								}
								className={pageUtils.homepageBannerClasses.button}
							/>

							<CreateAccountButton
								text={
									<>
										<span
											className={
												pageUtils.homepageBannerClasses.btnTextNoMobile
											}
										>
											{formatStrapiText(
												pageData.assets?.homepage_createAccount
											)}
										</span>
										<span
											className={pageUtils.homepageBannerClasses.btnTextMobile}
										>
											{formatStrapiText(pageData.assets?.btn_createAccount)}
										</span>
									</>
								}
								className={pageUtils.homepageBannerClasses.button}
							/>
						</div>
					</>
				)}
				<>
					<SearchRequests
						isLoading={isLoading}
						showClearTextIcon={isHomePageSearchInputNotEmpty()}
						isHomeScreen
						onSetIsLoading={setIsLoading}
						onSetRequests={setRequests}
						onNavigateToRequests={onNavigateToRequests}
						searchText={searchText}
						setSearchText={setSearchText}
					/>
				</>
			</>
		)
	}

	return (
		<div className={pageUtils.homepageBannerClasses.root}>
			<PageTitle title={formatStrapiText(pageData?.title)} />

			<SectionTitle
				title={title()}
				showBreadcrumbs={false}
				classRoot={pageUtils.homepageBannerClasses.sectionTitle}
				showCharterInfo={false}
			/>

			<div
				className={pageUtils.searchResultSectionClasses.searchResultsSection}
			>
				<div className={pageUtils.searchResultSectionClasses.searchResults}>
					<ReactCollapse
						isOpened={
							isLoading &&
							!isSearchBoxOpen &&
							searchText.length > 0 &&
							isHomePageSearchInputNotEmpty()
						}
					>
						<section
							className={pageUtils.searchResultSectionClasses.sectionPadding}
						>
							<Loader text={pageData?.assets?.loading} />
						</section>
					</ReactCollapse>

					<ReactCollapse
						isOpened={
							requests.length === 0 &&
							!isLoading &&
							searchText !== '' &&
							!isSearchBoxOpen &&
							isHomePageSearchInputNotEmpty()
						}
					>
						<section
							className={
								pageUtils.searchResultSectionClasses.sectionNoRequestFound
							}
						>
							<Text
								content={
									pageData?.assets?.page_resultsRequests_no_request_found
								}
							/>
						</section>
					</ReactCollapse>

					<ReactCollapse
						isOpened={
							requests.length > 0 &&
							!isLoading &&
							!isSearchBoxOpen &&
							isHomePageSearchInputNotEmpty()
						}
					>
						<section
							className={pageUtils.searchResultSectionClasses.sectionPadding}
						>
							{requests.slice(0, 5).map((request: Request, key: number) => (
								<RequestListCard
									id={key}
									request={request}
									index={key}
									requestThemes={pageData?.collections?.requestThemes}
									isHomePage={true}
								/>
							))}

							<button
								onClick={onNavigateToRequests}
								className={
									pageUtils.searchResultSectionClasses.btnSeeAllResults
								}
							>
								{formatStrapiText(pageData?.assets?.seeAllResults)}
							</button>
						</section>
					</ReactCollapse>
				</div>
			</div>
		</div>
	)
}

export default HomepageBanner
