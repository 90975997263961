import React, { FC, useContext } from 'react'
import {
	AppContextProps,
	AppStateContext
} from '@components/layouts/DynamicLayout'
import { chevronIcon, questionIcon } from '@images/icons'
import { Icon } from '@components/ui/Icon'
import * as pageUtils from '@components/homepage/__index.utils'
import Text from '@components/ui/text'
import { formatStrapiText } from '@utils/methods'
import { useAppDispatch } from '@services/store'
import { doOpenSearchHeader } from '@services/store/articles'

const HomepageSidebarContent: FC<pageUtils.HomepageSidebarContentProps> =
	() => {
		const { pageData } = useContext<AppContextProps>(AppStateContext)
		const dispatch = useAppDispatch()

		const doOpenSearchBar = () => {
			dispatch(doOpenSearchHeader(true))
		}

		return (
			<div className={pageUtils.homepageSidebarContentClasses.root}>
				<div className={pageUtils.homepageSidebarContentClasses.container}>
					<div className={pageUtils.homepageSidebarContentClasses.title}>
						<Icon src={questionIcon} />
						{formatStrapiText(pageData?.assets?.homepage_sidebarContent_about)}
					</div>
					<div className={pageUtils.homepageSidebarContentClasses.content}>
						<Text
							content={pageData?.assets?.homepage_sidebarContent_listTitle}
						/>
						<ul className={pageUtils.homepageSidebarContentClasses.ul}>
							<li className={pageUtils.homepageSidebarContentClasses.li}>
								<Icon
									src={chevronIcon}
									className={pageUtils.homepageSidebarContentClasses.liImg}
								/>
								<Text
									content={
										pageData?.assets
											?.homepage_sidebarContent_listItem_sendRequests
									}
								/>
							</li>
							<li className={pageUtils.homepageSidebarContentClasses.li}>
								<Icon
									src={chevronIcon}
									className={pageUtils.homepageSidebarContentClasses.liImg}
								/>
								<Text
									content={
										pageData?.assets
											?.homepage_sidebarContent_listItem_makePayments
									}
								/>
							</li>
							<li className={pageUtils.homepageSidebarContentClasses.li}>
								<Icon
									src={chevronIcon}
									className={pageUtils.homepageSidebarContentClasses.liImg}
								/>
								<div>
									{formatStrapiText(
										pageData?.assets
											?.homepage_sidebarContent_listItem_consultInformation
									)}{' '}
									{formatStrapiText(
										pageData?.assets
											?.homepage_sidebarContent_listItem_consultInformation_toggle
									)}
								</div>
							</li>
							<li className={pageUtils.homepageSidebarContentClasses.li}>
								<Icon
									src={chevronIcon}
									className={pageUtils.homepageSidebarContentClasses.liImg}
								/>
								<Text
									content={
										pageData?.assets
											?.homepage_sidebarContent_listItem_checkStatus
									}
								/>
							</li>
							<li className={pageUtils.homepageSidebarContentClasses.li}>
								<Icon
									src={chevronIcon}
									className={pageUtils.homepageSidebarContentClasses.liImg}
								/>
								<Text
									content={
										pageData?.assets
											?.homepage_sidebarContent_listItem_addAdditionalInformation
									}
								/>
							</li>
							<li className={pageUtils.homepageSidebarContentClasses.li}>
								<Icon
									src={chevronIcon}
									className={pageUtils.homepageSidebarContentClasses.liImg}
								/>
								<Text
									content={
										pageData?.assets
											?.homepage_sidebarContent_listItem_reviewRequest
									}
								/>
							</li>
							<li className={pageUtils.homepageSidebarContentClasses.li}>
								<Icon
									src={chevronIcon}
									className={pageUtils.homepageSidebarContentClasses.liImg}
								/>
								<Text
									content={
										pageData?.assets
											?.homepage_sidebarContent_listItem_cancelRequest
									}
								/>
							</li>
						</ul>
						<p>
							{formatStrapiText(
								pageData?.assets?.homepage_sidebarContent_moreInfo
							)}{' '}
							{/* @TODO Add link when available... */}
							{formatStrapiText(pageData?.assets?.homepage_sidebarContent_faq)}
						</p>
					</div>
				</div>
			</div>
		)
	}

export default HomepageSidebarContent
